/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import store from '@/store/index';

const FL_API_URL = process.env.VUE_APP_FL_API_URL;

const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const loginByAuth = async (email: string, password: string) => {
    try {
        const response = await axios.post(FL_API_URL + "/user/authenticate", {email, password});
        return response.data.token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const registerByAuth = async (email: string, password: string) => {
    try {
        const token = '';// await
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const getProfile = async () => {
    try {
        const userProfile = await axios.get(FL_API_URL + "/user/profile", { headers:  authHeader() })
        const user = {
            ID: userProfile.data.id,
            username: userProfile.data.firstName + ' ' + userProfile.data.lastName,
            firstName: userProfile.data.firstName,
            lastName: userProfile.data.lastName,
            email: userProfile.data.email,
            company: userProfile.data.company,
            position: userProfile.data.position,
            picture: userProfile.data.picture ? userProfile.data.picture : "/img/default-profile.b80a382d.png",
            isVerified: true,
            provider: userProfile.data.company,
            socialID: "",
            metadata: { },
            createdAt: userProfile.data.createdAt,
            updatedAt: "2016-01-25T09:08:34.123+06:00",
            apps: userProfile.data.apps
        }
        return user;
    } catch (error: any) {
        throw getError(error);
    }
};

export const changePassword = async (current: string, newPassword: string, retypedPassword: string) => {
    try {
        const response = await axios.post(FL_API_URL + "/user/changePassword", {current, newPassword, retypedPassword}, { headers:  authHeader() });
        return response.data.token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const authHeader = () => {
    if (store.getters['auth/token']) {
        return { Authorization: 'Bearer ' + store.getters['auth/token'] };
    } else {
        return {};
    }
}
