import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "dropdownMenu",
    class: _normalizeClass(["dropdown-menu dropdown-menu-right show", `dropdown-menu-${_ctx.size}`]),
    style: _normalizeStyle(_ctx.fixStyles)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 6))
}