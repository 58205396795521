import {Options, Vue} from 'vue-class-component';
import { IUser } from "@/interfaces/user";
import { useToast } from "vue-toastification";
import { changePassword } from "@/services/auth";

@Options({})
export default class Profile extends Vue {
    private activeTab = 'SETTINGS';
    public current: string = '';
    public newPassword: string = '';
    public retypedPassword: string = '';
    private toast = useToast();

    private setActiveTab(tab: string): void {
        this.activeTab = tab;
    }
    get user(): IUser {
        return this.$store.getters['auth/user'];
    }

    public async changePassword(): Promise<void> {
        if (this.current == '' || this.newPassword == '') {
            this.toast.error("Password can't be empty");
            return;
        }
        if (this.newPassword != this.retypedPassword) {
            this.toast.error("New passwords should be matched");
            return;
        }
        try {
            await changePassword(this.current, this.newPassword, this.retypedPassword);
            this.current = '';
            this.newPassword = '';
            this.retypedPassword = '';
            this.toast.success('Password updated successfully');
        } catch (error: any) {
            this.toast.error('Password update error');
        }
    }
}
