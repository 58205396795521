import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "name", "disabled"]
const _hoisted_4 = ["selected"]
const _hoisted_5 = ["value", "selected"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.ID,
      class: "select-label"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_2),
    _createElementVNode("select", {
      class: _normalizeClass({'form-control': _ctx.type === 'default', 'custom-select': _ctx.type === 'custom'}),
      id: _ctx.ID,
      name: _ctx.ID,
      disabled: _ctx.disabled
    }, [
      _createElementVNode("option", { selected: _ctx.isNoneSelected }, "None", 8, _hoisted_4),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          value: option.value,
          selected: _ctx.modelValue === option.value
        }, _toDisplayString(option.label), 9, _hoisted_5))
      }), 256))
    ], 10, _hoisted_3)
  ]))
}