import {createStore} from 'vuex';
import authModule from './auth';
import uiModule from './ui';
import model from './model';

export default createStore({
    modules: {
        auth: authModule,
        ui: uiModule,
        model: model
    }
});
