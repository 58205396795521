import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const model = {
    namespaced: true,
    state: {
        selectedAppId: -1
    },
    mutations,
    actions,
    getters
};

export default model;
