import {IUser} from '@/interfaces/user';
import {Options, Vue} from 'vue-class-component';
import MenuItem from '@/components/menu-item/menu-item.vue';

@Options({
    name: 'app-menu-sidebar',
    components: {
        'app-menu-item': MenuItem
    }
})
export default class MenuSidebar extends Vue {
    public menu = MENU;
    get user(): IUser {
        return this.$store.getters['auth/user'];
    }

    get sidebarSkin() {
        return this.$store.getters['ui/sidebarSkin'];
    }
}

export const MENU = [
    {
        name: 'labels.dashboard',
        icon: 'fa-tachometer-alt',
        path: '/',
        permission: 'dashboard-page'
    },
    {
        name: 'labels.accessAPIs',
        icon: 'fa-home',
        path: '/accessapis',
        permission: 'access-api-page'
    },
    {
        name: 'labels.models',
        icon: 'fa-chart-pie',
        path: '/models',
        permission: 'models-page'
    },
    {
        name: 'labels.profile',
        icon: 'fa-user',
        path: '/profile'
    },
    {
        name: 'labels.contact',
        icon: 'fa-comment-alt',
        // children: [
        //     {
        //         name: 'labels.subMenu',
        //         path: '/sub-menu-1'
        //     },
        // ],
        path: '/contact'
    }
];
