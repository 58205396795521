/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import store from '@/store/index';

const FL_API_URL = process.env.VUE_APP_FL_API_URL;

const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const getApplications = async (includeML = false) => {
    try {
        return await axios.get(FL_API_URL + "/web/application?includeML=" + includeML, { headers:  authHeader() });
    } catch (error: any) {
        throw getError(error);
    }
};

export const getDashboardInfo = async (appID = 2) => {
    try {
        return await axios.get(FL_API_URL + "/web/dashboard?appID=" + appID, { headers:  authHeader() });
    } catch (error: any) {
        throw getError(error);
    }
};

export const authHeader = () => {
    if (store.getters['auth/token']) {
        return { Authorization: 'Bearer ' + store.getters['auth/token'] };
    } else {
        return {};
    }
}
