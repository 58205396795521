import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c945158"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_app_dropdown = _resolveComponent("app-dropdown")

  return (_ctx.apps && _ctx.apps.length > 1)
    ? (_openBlock(), _createBlock(_component_app_dropdown, { key: 0 }, {
        "dropdown-button": _withCtx(() => [
          _createTextVNode(" Model instance: " + _toDisplayString(_ctx.selectedApp.name), 1)
        ]),
        "dropdown-menu": _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.apps, (app) => {
            return (_openBlock(), _createElementBlock("a", {
              class: _normalizeClass(["dropdown-item", {'active': _ctx.selectedApp.id === app.id}]),
              onClick: $event => (_ctx.changeApp(app))
            }, _toDisplayString(app.name), 11, _hoisted_1))
          }), 256))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}