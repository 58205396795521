import { Options, Vue } from "vue-class-component";
import { getWebhookCategories, getWebhooksConfig, submitWebhook } from "@/services/accessapi";
import { useToast } from "vue-toastification";
import { IWebhook } from "@/interfaces/webhook";

@Options({})
export default class AccessAPIs extends Vue {
  private toast = useToast();
  private editMode = false;
  private editWebhook: IWebhook = { appId: null, type: null, method: null, uri: null, fcmServerKey: null, notificationTitle: null, notificationBody: null, categories: [], enabled: false };
  private webhooksTypes = [{ name: "Slack", value: "Slack" }, { name: "JSON", value: "Webhook" }, { name: "Firebase Cloud Messaging", value: "Firebase" }];
  private categories = {};
  private categoryMap: any = {};
  private webhooks: any = [];

  private create() {
    const selectedApp = this.$store.getters["model/selectedAppId"];
    this.editWebhook = {
      "appId": selectedApp,
      "type": "",
      "method": "Post",
      "uri": "",
      "fcmServerKey": "",
      "notificationTitle": "",
      "notificationBody": "",
      "categories": [],
      "enabled": true
    };
    this.editMode = true;
  }

  private edit(index: number) {
    this.editWebhook = { ...this.webhooks[index] };
    this.editMode = true;
  }

  private save() {
    if (this.editWebhook.type == "Firebase") {
      this.editWebhook.uri = "https://fcm.googleapis.com/fcm/send";
    } else {
      this.editWebhook.fcmServerKey = "";
    }
    submitWebhook(this.editWebhook).then(response => {
      (response.status == 200)
        ? this.toast.success("Webhook configuration saved successfully!")
        : this.toast.error("Failed to save Webhook configuration!");
      this.editMode = false;
      this.refreshWebhooks();
    });
  }

  private cancel() {
    this.editMode = false;
    this.editWebhook = null;
  }

  private refreshWebhooks() {
    const selectedApp = this.$store.getters["model/selectedAppId"];
    getWebhooksConfig(selectedApp).then(response => {
      this.webhooks = response.data.webhooks;
    });
    getWebhookCategories(selectedApp).then(response => {
      this.categories = response.data;
      this.categoryMap = {};
      Object.entries(this.categories).forEach(([k, v]) => {
        this.categoryMap[v.toString()] = k;
      });
    });
  }

  public mounted(): void {
    this.editMode = false;
    this.refreshWebhooks();
  }
}
