import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74a39263"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_app_dropdown = _resolveComponent("app-dropdown")

  return (_openBlock(), _createBlock(_component_app_dropdown, null, {
    "dropdown-button": _withCtx(() => [
      _createElementVNode("i", {
        class: _normalizeClass(["flag-icon", _ctx.flagIcon])
      }, null, 2)
    ]),
    "dropdown-menu": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
        return (_openBlock(), _createElementBlock("a", {
          class: _normalizeClass(["dropdown-item", {'active': _ctx.selectedLanguage === language.key}]),
          onClick: $event => (_ctx.changeLanguage(language.key))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["flag-icon mr-2", language.flag])
          }, null, 2),
          _createTextVNode(" " + _toDisplayString(_ctx.$t(language.label)), 1)
        ], 10, _hoisted_1))
      }), 256))
    ]),
    _: 1
  }))
}