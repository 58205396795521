import { Options, Vue } from "vue-class-component";
import { getDashboardInfo } from "@/services/dashboard";
import { Chart, ChartData, ChartOptions, ChartTooltipItem } from "chart.js";
import { selectDefaultApp } from "@/utils/helpers";
import { getProfile } from "@/services/auth";

@Options({})
export default class Dashboard extends Vue {
  private dashboard = {
    successPrediction: 0,
    successPredictionABRandom: 0,
    successPredictionTrend: 0,
    totalData: 0,
    totalDataTrend: 0,
    totalDevices: 0,
    totalDevicesTrend: 0,
    learningIterations: 0,
    successPredictionRate: {
      labels: ["1","2"],
      data: [0, 0]
    },
    deviceParticipating: 0
  };

  private lineChartData = () => {
    return {
      labels: this.dashboard.successPredictionRate.labels,
      datasets: [
        {
          fill: false,
          backgroundColor: "rgba(60,141,188,0.9)",
          borderColor: "rgba(60,141,188,0.8)",
          pointRadius: 3,
          data: this.dashboard.successPredictionRate.data
        }
      ]
    };
  };

  private lineChartOptions : ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems, data) {
          return data.datasets[0].data[tooltipItems.index] + '%';
        },
        title: (item: Chart.ChartTooltipItem[], data: Chart.ChartData) => {
          return "Iteration " + data.labels[item[0].index];
        }
      }
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Iteration'
        },
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Accuracy'
        },
        ticks: {
            beginAtZero: true,
                min: 0,
                callback: function(value){return value+ "%";}
            },
        gridLines: {
          display: true
        }
      }]
    }
  };

  private donutData = () => {
    return {
      labels: ["Participating", "Not Participating"],
      datasets: [
        {
          data: [this.dashboard.deviceParticipating, this.dashboard.totalDevices - this.dashboard.deviceParticipating],
          backgroundColor: ["#2f3ab2"]
        }
      ]
    };
  };

  private donutOptions : ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    title: {
      display: true,
      position: 'top',
      text: ' %',
      fontSize: 18,
      fontColor: "#2f3ab2"
    },
    legend: {
      display: false
    }
  };

  private toReadable = (size: string) => {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let l = 0, n = parseInt(size, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l]);
  };

  public mounted(): void {
    let selectedAppId = selectDefaultApp(this.$store)
    if (selectedAppId >= 0) {
      this.renderDashboard(selectedAppId);
    }
    else {
      getProfile().then(profile => {
        if (profile.apps.length > 0) {
          selectedAppId = profile.apps[0].id;
          if (selectedAppId == 1) {
            selectedAppId = profile.apps[1].id;
          }
        }
        this.renderDashboard(selectedAppId);
      })
    }
  }

  public renderDashboard(appId: number) : void {
    getDashboardInfo(appId).then(response => {
      this.dashboard = response.data;
      this.dashboard.successPredictionRate.labels.pop();
      this.dashboard.successPredictionRate.data.pop();
      if (Math.max(...this.dashboard.successPredictionRate.data) > 100) {
        this.lineChartOptions.scales.yAxes[0].ticks.max = 100;
      }
      if (this.dashboard.successPredictionRate.data.length == 0) {
        this.lineChartOptions.scales.yAxes[0].ticks.max = 100;
      }
      this.dashboard.successPredictionRate.data = this.dashboard.successPredictionRate.data.map(spr => Math.round(spr*100)/100)

      const lineChart = new Chart(lineChartCanvas.getContext("2d"), {
        type: "line",
        data: this.lineChartData(),
        options: this.lineChartOptions
      });

      this.donutOptions.title.text = this.dashboard.totalDevices == 0 ? 'N/A' : Math.round(100 * this.dashboard.deviceParticipating / this.dashboard.totalDevices) + "%"
      new Chart(donutChartCanvas.getContext("2d"), {
        type: "doughnut",
        data: this.donutData(),
        options: this.donutOptions
      });
    });

    const lineChartCanvas = document.getElementById("lineChart") as HTMLCanvasElement;
    const donutChartCanvas = document.getElementById("donutChart") as HTMLCanvasElement;
  }
}
