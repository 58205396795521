import { Options, Vue } from "vue-class-component";
import axios from "axios";
import { authHeader, getApplications } from "@/services/dashboard";
import { IModel } from "@/interfaces/model";

@Options({})
export default class Models extends Vue {
  private models: Array<IModel> = [];

  public mounted(): void {
    getApplications(true).then(response => {
      this.models = response.data;
    });
  }

  private toPercentage = (size: number) => {
    if (isNaN(size)) {
      return "-";
    }
    return (size * 100).toFixed(2) + "%";
  };

  private downloadModel = (appId: number, mlId: number) => {
    console.log("calling donwloadModel " + appId + " " + mlId);
    axios({
      url: `${process.env.VUE_APP_FL_API_URL}/web/getModel?appID=${appId}&mlID=${mlId}`,
      method: "GET",
      headers: authHeader(),
      responseType: "blob"
    })
      .then((response) => {
        const url = window.URL
          .createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename = `model-${appId}-${mlId}.json`;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };
}
