import { Options, Vue } from "vue-class-component";
import Dropdown from "@/components/dropdown/dropdown.vue";
import { IApps } from "@/interfaces/app";
import { selectDefaultApp } from "@/utils/helpers";
import { getProfile } from "@/services/auth";

@Options({
    name: "ml-apps-dropdown",
    components: {
        "app-dropdown": Dropdown
    }
})
export default class MlApps extends Vue {
    public selectedApp: IApps = { id: -1, name: "N/A" };

    public apps: Array<IApps> = []

    getApps() {
        const data = this.$store.getters["auth/user"];
        if (!data) {

            getProfile().then(profile => {
                this.apps = profile.apps;
                this.selectApp();
            });
        }
        else if (data.apps && data.apps.length == 1) {
            this.selectedApp = data.apps[0];
            this.$store.dispatch("model/setSelectedApp", this.selectedApp.id);
            this.apps = data.apps;
            this.selectApp();
        }
    }

    public mounted() {
        this.getApps();
    }

    private selectApp() {
        const selectedAppId = selectDefaultApp(this.$store)
        this.selectedApp = this.apps && this.apps.length == 0 ? { id: -1, name: "N/A" } : this.apps.find(a => a.id == selectedAppId);
    }

    public changeApp(app: IApps) {
        this.$store.dispatch('model/setSelectedApp', app.id);
        this.selectedApp = app;
    }
}
