import axios from "axios";
import { authHeader } from "@/services/auth";

const FL_API_URL = process.env.VUE_APP_FL_API_URL;

const getError = (error: any) => {
    const message = error.message || "Failed";
    return new Error(message);
};

export const getWebhooksConfig = async (appID = 2) => {
    try {
        return await axios.get(FL_API_URL + "/web/webhooks?appID=" + appID, { headers: authHeader() });
    } catch (error: any) {
        throw getError(error);
    }
};

export const getWebhookCategories = async (appID = 2) => {
    try {
        return await axios.get(FL_API_URL + "/web/webhooks/categories?appID=" + appID, { headers: authHeader() });
    } catch (error: any) {
        throw getError(error);
    }
};

export const submitWebhook = async (webhook: any) => {
    try {
        const response = await axios.post(FL_API_URL + "/web/webhooks", webhook, { headers: authHeader() });
        return response;
    } catch (error: any) {
        throw getError(error);
    }
};
