import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c83f01de"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = {
  key: 1,
  class: "spinner-border ml-2",
  role: "status"
}
const _hoisted_3 = { class: "sr-only" }

export function render(_ctx, _cache) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass({'btn':true, 'btn-block': _ctx.block}),
    type: _ctx.type,
    disabled: _ctx.isDisabled
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          class: "font-awesome-icon mr-2",
          icon: ['fab', _ctx.icon]
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("labels.loading")), 1)
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}