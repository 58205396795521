import { Store } from "vuex";

export const calculateWindowSize = (windowWidth: number): string => {
    if (windowWidth >= 1200) {
        return 'lg';
    }
    if (windowWidth >= 992) {
        return 'md';
    }
    if (windowWidth >= 768) {
        return 'sm';
    }
    return 'xs';
};

export const selectDefaultApp = (store: Store<any>): number => {
    let selectedAppId = store.getters["model/selectedAppId"];
    if (selectedAppId && selectedAppId >= 0) {
        return selectedAppId;
    }
    const data = store.getters["auth/user"];
        if (!data) {
            return -1;
        }
        if (data.apps && data.apps.length == 1) {
            const selectedApp = data.apps[0];
            selectedAppId = selectedApp.id;
            store.dispatch('model/setSelectedApp', selectedApp.id);
        }
        if ((!selectedAppId || selectedAppId === -1) && data.apps.length > 0) {
            selectedAppId = data.apps[0].id;
            if (selectedAppId == 1) {
                selectedAppId = data.apps[1].id;
                store.dispatch('model/setSelectedApp', selectedAppId);
            }
        }
        return selectedAppId;
};
